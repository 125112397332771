import { useEffect, useCallback } from 'react';
import { useAppContext } from '../state/state';
import Header from '../layouts/header';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { TagsService } from '../services/TagsService';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

import './Leaderboard.scss';
import { ArrowBack } from '@mui/icons-material';

const StyledSelectItem = styled('div')(({ theme, selected }) => ({
    padding: '15px',
    background: '#FFF',
    borderBottom: '1px solid #efefef',
    display: 'flex',
    cursor: 'pointer',
    backgroundColor: selected ? '#e6f7ff' : '#FFF',
}));

const SelectItem = ({ type }) => {
    const navigate = useNavigate();
    const tagService = TagsService.getInstance();
    const location = useLocation();
    const [isLoading, setIsLoading] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [title, setTitle] = React.useState('Leaderboard');

    const { state, dispatch, setHeaderContent} = useAppContext();
    const { multiSelect = false } = location.state || {};

    const exit = useCallback(() => {
        navigate(-1);
    }, [navigate]);


    useEffect(() => {
        setTitle(`Select ${type}`);
        setIsLoading(true);
        tagService.getAllTags(type).then((tags) => {
          setItems(tags.map((tag) => ({  name: tag.value, color: '#000', description: tag.type, key: tag.key, type: tag.type, id: tag.id })));
          setIsLoading(false);
        });
    }, [type]);

    useEffect(() => {
        setHeaderContent({ element: 
            <Header title={title} actions={{
                start: <div onClick={exit}><ArrowBack/></div>,
            }}>
                {isLoading && <LinearProgress />}
            </Header> 
        });
        return () => setHeaderContent(null); // Clean up when component unmounts
    }, [exit, isLoading, setHeaderContent, title]);

    const handleItemClick = (item) => {
        let newSelectedItems;
       
        if (multiSelect) {
            // Multi-select mode
            if (state.selectedItems.some(selectedItem => selectedItem.id === item.id)) {
                // If item is already selected, remove it
                newSelectedItems =  state.selectedItems.filter(selectedItem => selectedItem.id !== item.id);
            } else {
                // If item is not selected, add it
                newSelectedItems = [...state.selectedItems, item];
            }
        } else {
            // Single-select mode
            if (state.selectedItems.length === 1 && state.selectedItems[0].id === item.id) {
                // If the clicked item is already selected, deselect it
                newSelectedItems = [];
            } else {
                // Otherwise, select only this item
                newSelectedItems = [item];
            }
        }
        dispatch({ type: 'SET_SELECTED_ITEMS', payload: newSelectedItems });

    };

    return (
        <div>
            {items.map((item) => (
            <StyledSelectItem 
                key={item.id}
                selected={state.selectedItems.some(selectedItem => selectedItem.id === item.id)}
                onClick={() => handleItemClick(item)}
            >
              {item.name}
            </StyledSelectItem>
            ))}
        </div>
    );
}

export default SelectItem;
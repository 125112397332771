import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LeaderboardService } from '../services/LeaderboardService';
import ListProfileComponent from '../components/ListProfileComponent';
import { useAppContext } from '../state/state';
import Leaders from '../components/Leaders';
import { Box } from '@mui/material';
import Header from '../layouts/header';
import AppsIcon from '@mui/icons-material/Apps';
import ArrowBack from '@mui/icons-material/ArrowBack';
import * as React from 'react';
import { Tabs, Tab, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LeaderboardsList from './LeaderboardsList';
import LeaderboardForm from '../components/LeaderboardForm';


import './Leaderboard.scss';

const StyledTabs = styled(Tabs)(({ theme }) => ({
    minHeight: 'auto',
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start',
    },
    '& .MuiTab-root': {
      minWidth: 'auto',
      padding: '6px 16px',
    },
  }));
  
  const CenteredTabsContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  });

const LeaderboardCreate = () => {
    const navigate = useNavigate();
    const leaderboardService = LeaderboardService.getInstance();
    const { state, dispatch, setHeaderContent} = useAppContext();

    const { type, id } = useParams();

    const saveLeaderboard = () => {

    }

    const exit = () => {
        navigate(-1);
    }

    useEffect(() => {
        setHeaderContent({ element: 
            <Header title={'Create Leaderboard'} actions={{
                start: <div onClick={exit}><ArrowBack/></div>,
            }}>
               
            </Header> 
        });
        return () => setHeaderContent(null); // Clean up when component unmounts
      }, []);

    return (
        <Box >
            <LeaderboardForm />
        </Box>

    );
}
export default LeaderboardCreate;
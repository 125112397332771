import { ApiService } from './ApiService'; // Assuming you have this file

export interface LeaderBoardFilter {
   id?: string, group?: string 
}
export class LeaderboardService {
  private static instance: LeaderboardService;
  private api: ApiService;

  private constructor() {
    this.api = ApiService.getInstance();
  }

  public static getInstance(): LeaderboardService {
    if (!LeaderboardService.instance) {
      LeaderboardService.instance = new LeaderboardService();
    }
    return LeaderboardService.instance;
  }

  get(leaderboard: string, filter?: LeaderBoardFilter) {
    return this.getById(leaderboard, filter);
  }

  async list() {
    return await this.api.get<any>('leaderboards');
  }

  async getByPoints(filter?: LeaderBoardFilter) {
    const leaderboard = await this.api.get<any>('leaderboard/points', filter);
    return leaderboard;
  }

  async getByStreak(filter?:LeaderBoardFilter) {
    const leaderboard = await this.api.get<any>('leaderboard/streak', filter);
    return leaderboard;
  }

  async getById(id: any, filter?:LeaderBoardFilter) {
    const leaderboard = await this.api.get<any>(`leaderboard/${id}`, filter);
    return leaderboard;
  }

  async getByWorkout(filter: LeaderBoardFilter) {
    const leaderboard = await this.api.get<any>(`leaderboard/workout/${filter.id}`, filter);
    return leaderboard;
  }

  async create(tags: any, dateRange: string | null, name: string) {
    const leaderboard = await this.api.post<any>(`leaderboard`, { tags, dateRange , name});
    return leaderboard;
  }

  async getByWorkoutCount(filter?: LeaderBoardFilter) {
    const leaderboard = await this.api.get<any>('leaderboard/workouts', filter);
    return leaderboard;
  }
}

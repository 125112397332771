import * as React from 'react';
import { styled } from '@mui/material/styles';
import { TagsService } from '../services/TagsService';
import { Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import PipeService from '../services/PipeService';
import { LeaderboardService } from '../services/LeaderboardService';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../state/state';
import Footer from '../layouts/footer';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useLocation } from 'react-router-dom';

const StyledTagAvatar = styled('div')(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '50%',
  backgroundColor: '#000',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 12,
  ...theme.applyStyles('dark', {
    backgroundColor: '#fff',
    color: '#000',
  }),
}));

const StyledFormOption = styled('div')(({ theme }) => ({
  borderRadius: '10px',
  backgroundColor: 'rgba(0,0,0,0.5)',
  color: '#fff',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '15px',
  margin:'10px',
  alignItems: 'center',
}));

const StyledFormOptionTitle = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
}));

const StyledFormOptionItem = styled('span')(({ theme }) => ({
  color:'#FFF',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
}));

const StyledChips = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  gap: 10,
  width: '100%',
  overflowX: 'auto',
  [`.selected`]: {
    backgroundColor: 'var(--bf-color-primary)',
    color: '#FFF'
  },
}));

const options = [
  {
    url: '/workout/select',
    multiSelect: false,
    title: 'Workout',
    subTitle: 'Select a workout'
  },
  {
    url: '/exercise/select',
    multiSelect: true,
    title: 'Exercises',
    subTitle: 'Select exercises'
  },
  {
    url: '/tags/select',
    multiSelect: true,
    title: 'Tags',
    subTitle: 'Select tags'
  }
]


export default function LeaderboardForm() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [value, setValue] = React.useState<{ [key: string]: LabelType[]}>({});
  const [items, setItems] = React.useState<LabelType[]>([]);
  const [pendingValue, setPendingValue] = React.useState<LabelType[]>([]);
  const [name, setName] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showNameDialog, setShowNameDialog] = React.useState<boolean>(false);
  const tagService = TagsService.getInstance();
  const leaderboardService = LeaderboardService.getInstance();
  const { avatar } = PipeService.getInstance();
  const { state, dispatch, setFooterContent} = useAppContext();
  const location = useLocation();
  const [ workout, setWorkout ] = React.useState<any>(null);
  const [ exercises, setExercises ] = React.useState<any>(null);
  const [ tags, setTags ] = React.useState<any>(null);
  const [ users, setUsers ] = React.useState<any>(null);


  const groupByProperty = <T extends Record<string, any>>(
    array: T[],
    property: keyof T
  ): Record<string, T[]> => {
    return array.reduce((acc, obj) => {
      const key = String(obj[property]);
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {} as Record<string, T[]>);
  }

  const handleClose = () => {
    setValue(groupByProperty(pendingValue, 'type'));
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  
  React.useEffect(() => {
    console.log('Selected items:', state.selectedItems);
    const workout = state.selectedItems.find((item: any) => item.type === 'workout');
    if (workout) {
      console.log(workout)
      setWorkout(workout);
    }
    // Handle the selected items here
  }, [state.selectedItems]);


  const save = () => {
    setShowNameDialog(false);
    leaderboardService.create(pendingValue, null, name);
    navigate('/leaderboards');
  }

  React.useEffect(() => {
    setFooterContent({ element: 
        <Footer>
          <Button className="default" onClick={() => setShowNameDialog(true)}>Save</Button>
        </Footer> 
    });
    return () => setFooterContent(null); // Clean up when component unmounts
  }, []);

  
  return (
    <React.Fragment>
      {options.map((option) => (
        <StyledFormOption onClick={() => { navigate(option.url, { "state": { multiSelect: option.multiSelect}})}}>
          <StyledFormOptionTitle>{option.title}</StyledFormOptionTitle>
          <StyledFormOptionItem>{option.subTitle} <ChevronRightIcon></ChevronRightIcon></StyledFormOptionItem>
        </StyledFormOption>
      ))}

      <StyledFormOption onClick={() => { navigate('/workout/select')}}>
        <StyledFormOptionTitle>Start Date</StyledFormOptionTitle>
        <StyledFormOptionItem>100 Burpees <ChevronRightIcon></ChevronRightIcon></StyledFormOptionItem>
      </StyledFormOption>

      <StyledFormOption onClick={() => { navigate('/workout/select')}}>
        <StyledFormOptionTitle>End Date</StyledFormOptionTitle>
        <StyledFormOptionItem>100 Burpees <ChevronRightIcon></ChevronRightIcon></StyledFormOptionItem>
      </StyledFormOption>

      <StyledFormOption onClick={() => { navigate('/users/select', { "state": { multiSelect: true}})}}>
        <StyledFormOptionTitle>Users</StyledFormOptionTitle>
        <StyledFormOptionItem>100 Burpees <ChevronRightIcon></ChevronRightIcon></StyledFormOptionItem>
      </StyledFormOption>

      <StyledFormOption onClick={() => { navigate('/workout/select')}}>
        <StyledFormOptionTitle>Group by</StyledFormOptionTitle>
        <StyledFormOptionItem>100 Burpees <ChevronRightIcon></ChevronRightIcon></StyledFormOptionItem>
      </StyledFormOption>
      
      <Dialog
        open={showNameDialog}
        onClose={handleClose}
        PaperProps={{
          component: 'form',         
        }}
      >
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Leaderboard name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNameDialog(false)}>Cancel</Button>
          <Button onClick={save}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

interface LabelType {
  id: any;
  name: string;
  color: string;
  description: string;
  type?: string;
  key: any
  value?: string
  label?: string
  poster_url?: string
}

import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAppContext } from './state/state';
import { ApiService } from './services/ApiService';
import Home from './pages/Home';
import Share from './pages/Share';
import Leaderboard from './pages/Leaderboard';
import Workout from './pages/Workout';
import WorkoutLog from './pages/WorkoutLog';
import Layout from './layouts/main';
import LeaderboardCreate from './pages/LeaderboardCreate';
import LeaderboardsList from './pages/LeaderboardsList';
import SelectItem from './pages/SelectItem';

function AppRoutes() {
  const { dispatch, state } = useAppContext();
  const apiService = ApiService.getInstance();

  useEffect(() => {

    function handleMessage(event: MessageEvent) {
      if (event.data.type === 'AUTH_TOKEN') {
        apiService.setToken(event.data.token);
        dispatch({ type: 'SET_TOKEN', payload: !!event.data.token });
      }
    }

    window.parent.postMessage({ type: 'PORTAL_READY'}, '*');
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [dispatch, apiService]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/share" element={<Share />} />
        <Route path="/leaderboards" element={<LeaderboardsList />} />
        <Route path="/leaderboard/create" element={<LeaderboardCreate />} />
        <Route path="/leaderboard/:type?/:id?" element={<Leaderboard />} />
        <Route path="/workout/select" element={<SelectItem type='workout' />} />
        <Route path="/workout/:id?" element={<Workout />} />
        <Route path="/workout-log/:id" element={<WorkoutLog />} />
        <Route path="/exercise/select" element={<SelectItem type='exercise' />} />
        <Route path="/tag/select" element={<SelectItem type='tag' />} />
        <Route path="/user/select" element={<SelectItem type='user' />} />
        <Route path="/group/select" element={<SelectItem type='group' />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;